
import { defineComponent, ref, onMounted, watch, onUnmounted } from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import useRate from "@/composables/useRate";

export default defineComponent({
  name: "TetherChart",
  setup() {
    const tetherChartRef = ref<HTMLDivElement>();
    const { response, tab } = useRate(false);
    let chart: ApexCharts;

    const options: ApexOptions = {
      chart: {
        type: "line",
        height: "100%",
        fontFamily: "IRANSansX, sans-serif",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: 2.5,
        curve: "smooth",
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
        y: {
          formatter: function (value) {
            return value.toLocaleString();
          },
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      series: [],
      xaxis: {
        categories: [],
        labels: {
          offsetX: 1,
        },
      },
    };

    onMounted(() => {
      chart = new ApexCharts(tetherChartRef.value, options);
      chart.render();

      const updateChart = () => {
        const current = tab.value || "buy";

        if (response.value) {
          chart.updateSeries(response.value.chart[current].series);
          chart.updateOptions({
            xaxis: {
              categories: response.value.chart[current].xaxis.categories,
            },
            colors:
              current === "buy"
                ? ["#00e397", "#008efb"]
                : ["#008efb", "#00e397"],
          });
        }
      };

      watch(
        () => tab.value,
        () => {
          updateChart();
        }
      );

      watch(
        () => response.value,
        (value) => {
          if (value) {
            updateChart();
          }
        }
      );
    });

    onUnmounted(() => {
      chart.destroy();
    });

    return {
      tetherChartRef,
      tab,
      response,
    };
  },
});
